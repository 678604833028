import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "common/components/LocalizationProvider";
import EditItemView from "EditItemView";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import preval from "preval.macro";
import moment from "moment";
import useMapStyles from "map/core/useMapStyles";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

const buildTimestamp = preval`module.exports = new Date().getTime();`;
const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#1F1D2B", // #1F1D2B'
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },

    "& .MuiInputBase-root": {
      color: "#57c7d4",
    },
  },
  details: {
    flexDirection: "column",
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
}));

const UserPage = () => {
  const t = useTranslation();
  const classes = useStyles();
  const [item, setItem] = useState();
  const mapStyles = useMapStyles();
  const getDateString = () => {
    const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
    const formattedDate = lastUpdateMoment.format("DD.MM.YYYY HH:mm:ss");
    return formattedDate;
  };

  const user = useSelector((state) => state.session.user);
  const [availableMaps, setAvailableMaps] = useState([]);

  useEffect(() => {
    if (mapStyles.length && user.attributes.activeMapStyles) {
      const activeStyles = user.attributes.activeMapStyles?.split(",");
      const filtered_ids = _.filter(mapStyles, (p) =>
        _.includes(activeStyles, p.id)
      );
      setAvailableMaps(filtered_ids);
    } else {
      setAvailableMaps(mapStyles);
    }

    mapStyles.filter((style) => style.available);
  }, [mapStyles]);

  return (
    <EditItemView endpoint="users" item={item} setItem={setItem} disableGoBack>
      {item && (
        <>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              style={{ backgroundColor: "#1F1D2B" }}
            >
              <Typography variant="subtitle1">
                {t("sharedShowDetails")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={`${classes.details} ${classes.root}`}>
              <TextField
                margin="normal"
                value={getDateString()}
                label="Build Date"
                variant="filled"
                inputProps={{ readOnly: true }}
              />
              <TextField
                margin="normal"
                value={item.name || ""}
                onChange={(event) =>
                  setItem({ ...item, name: event.target.value })
                }
                label={t("sharedName")}
                variant="filled"
                inputProps={{ readOnly: true }}
              />
              <TextField
                margin="normal"
                value={item.email || ""}
                onChange={(event) =>
                  setItem({ ...item, email: event.target.value })
                }
                label={t("userEmail")}
                variant="filled"
                inputProps={{ readOnly: true }}
              />
              {/* <TextField
                margin="normal"
                type="password"
                onChange={event => setItem({...item, password: event.target.value})}
                label={t('userPassword')}
                variant="filled" /> */}
              <TextField
                margin="normal"
                value={item.latitude || ""}
                onChange={(event) =>
                  setItem({ ...item, latitude: event.target.value })
                }
                label="Latitude"
                variant="filled"
              />
              <TextField
                margin="normal"
                value={item.longitude || ""}
                onChange={(event) =>
                  setItem({ ...item, longitude: event.target.value })
                }
                label="Longitude"
                variant="filled"
              />
              <TextField
                margin="normal"
                value={item.zoom || ""}
                onChange={(event) =>
                  setItem({ ...item, zoom: event.target.value })
                }
                label="Zoom"
                variant="filled"
              />

              <FormControl variant="filled" margin="normal" fullWidth>
                <InputLabel>Default Map</InputLabel>

                <Select
                  value={item.map}
                  onChange={(e) => {
                    setItem({ ...item, map: e.target.value });
                  }}
                  MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                >
                  {availableMaps.map((style) => (
                    <MenuItem key={style.id} value={style.id}>
                      {style.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                margin="normal"
                value={item.phone || ""}
                onChange={(event) =>
                  setItem({ ...item, phone: event.target.value })
                }
                label="Phone"
                helperText="Phone Number (must start with 61) e.g 61415205605"
                type="tel"
                variant="filled"
              />
            </AccordionDetails>
          </Accordion>
          {/* <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">
                {t('sharedAttributes')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <EditAttributesView
                attributes={item.attributes}
                setAttributes={attributes => setItem({...item, attributes})}
                definitions={userAttributes}
                />
            </AccordionDetails>
          </Accordion> */}
          {/* {item.id &&
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedConnections')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <LinkField
                  margin="normal"
                  endpointAll="/api/devices?all=true"
                  endpointLinked={"/api/devices?userId=" + item.id}
                  baseId={item.id}
                  keyBase="userId"
                  keyLink="deviceId"
                  label={t('deviceTitle')}
                  variant="filled" />
                <LinkField
                  margin="normal"
                  endpointAll="/api/groups?all=true"
                  endpointLinked={"/api/groups?userId=" + item.id}
                  baseId={item.id}
                  keyBase="userId"
                  keyLink="groupId"
                  label={t('settingsGroups')}
                  variant="filled" />
              </AccordionDetails>
            </Accordion>
          } */}
        </>
      )}
    </EditItemView>
  );
};

export default UserPage;
