import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import MainToolbar from "components/MainToolbar";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  Paper,
  Button,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  Chip,
  ListItemText,
  Input,
  Grid,
  ButtonGroup,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import MaterialTable from "material-table";
import { AddCircle, Edit, Save } from "@mui/icons-material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TextField from "@mui/material/TextField";
import { useTranslation } from "common/components/LocalizationProvider";
import EditCollectionView from "EditCollectionView";
import { formatBoolean } from "common/util/formatter";
import { DialogBox, convertUnicode, useEffectAsync } from "reactHelper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { sessionActions } from "store";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#12151e", // #1F1D2B'
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
      display: "none",
    },
  },
  form: {
    padding: theme.spacing(1, 2, 2),
  },
  buttonGroup: {
    marginTop: "15px",
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
  chip: {
    margin: 2,
    backgroundColor: "#616161",
    color: "#fff",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  warningroot: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& .MuiIcon-colorPrimary": {
      color: "#4ac7c9",
    },
  },
}));

const UsersView = ({ updateTimestamp, onMenuClick }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const t = useTranslation();
  const [items, setItems] = useState([]);
  const [isUserFormVisible, setIsUserFormVisible] = useState(false);
  const [SelectedRow, setSelectedRow] = useState();
  const [IsEdit, setIsEdit] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [warning, setWarning] = useState(null);
  const [applyDefaultAttributes, setApplyDefaultAttributes] = useState(false);
  const defaultAttributes = {
    adminGlobalSettings: "disabled",
    mapGeofences: false,
    positionItems:
      "speed,address,totalDistance,course,latitude,longitude,alarm,battery,batteryLevel,ignition,driverUniqueId",
    activeMapStyles:
      ",googleRoad,googleSatellite,googleHybrid,darkMode,lightMode",
  };

  const handleWarningClose = () => {
    setOpenWarning(false);
  };

  const validateForm = () => {
    if (
      _.find(items, (o) => o.email == UserObject.email && o.id != UserObject.id)
    ) {
      setWarning("Please use Unique Email Address");
      setOpenWarning(true);
      return false;
    }
    return true;
  };

  const [UserObject, setUserObject] = useState({
    id: -1,
    name: "",
    email: "",
    password: "",
    phone: "",
    disabled: "enabled",
    administrator: "disabled",
    attributes: { ...defaultAttributes },
  });

  useEffectAsync(async () => {
    const response = await fetch("/api/users");
    if (response.ok) {
      setItems(await response.json());
    }
  }, [updateTimestamp]);

  const EditUser = () => {
    if (validateForm()) {
      const notificators = [];

      const UserObjectTemp = {
        id: UserObject?.id,
        name: UserObject?.name,
        email: UserObject?.email,
        phone: UserObject?.phone,
        disabled: UserObject.disabled == "disabled",
        administrator: UserObject.administrator == "enabled",
        attributes: applyDefaultAttributes
          ? { ...UserObject.attributes, ...defaultAttributes }
          : UserObject.attributes,
      };

      if (UserObject.hasOwnProperty("password") && UserObject.password) {
        UserObjectTemp.password = UserObject?.password;
      }

      fetch(`/api/users/${UserObject.id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(UserObjectTemp),
      })
        .then((res) => res.json())
        .then((result) => {
          // ResetForm();
          handleClick();
          setIsEdit(false);
          handleSessionRefresh();
          setIsUserFormVisible(false);
          handleReset();
        });
    }
  };

  const handleClick = async () => {
    getAllUser();
  };

  const handleSessionRefresh = async () => {
    const response = await fetch("/api/session");
    if (response.ok) {
      dispatch(sessionActions.updateUser(await response.json()));
    }
  };

  const handleReset = () => {
    setIsUserFormVisible(false);
    setSelectedRow(null);
    setIsEdit(false);
    setUserObject({
      id: -1,
      name: "",
      email: "",
      password: "",
      phone: "",
      disabled: "enabled",
      administrator: "disabled",
      attributes: { ...defaultAttributes },
    });
  };

  const getAllUser = async () => {
    const response = await fetch("/api/users");
    if (response.ok) {
      const data = await response.json();
      setItems(data);
    }
  };

  const addNewUser = () => {
    if (validateForm()) {
      const UserObjects = {
        ...UserObject,
        disabled: UserObject.disabled == "disabled",
        id: 0,
        readonly: false,
        administrator: UserObject.administrator == "enabled",
        map: "",
        latitude: "",
        longitude: "",
        zoom: 10,
        twelveHourFormat: false,
        coordinateFormat: "",
        expirationTime: "",
        deviceLimit: -1,
        userLimit: 0,
        deviceReadonly: false,
        limitCommands: false,
        poiLayer: "",
        attributes: {
          ...defaultAttributes,
          adminGlobalSettings: UserObject.attributes.adminGlobalSettings,
        },
      };

      fetch("/api/users", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(UserObjects),
      })
        .then((res) => res.json())
        .then((result) => {
          // ResetForm();
          handleClick();
          handleSessionRefresh();
          handleReset();
        });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <WarningDialog
          closeWarningCB={handleWarningClose}
          openWarning={openWarning}
          warning={warning}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} lg={2}>
            <Paper className={classes.form}>
              <ButtonGroup>
                {/* { false ?
                <Button color="secondary" onClick={() => {}} >Close</Button> :
                 */}

                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<AddCircle />}
                  onClick={() => setIsUserFormVisible(!isUserFormVisible)}
                >
                  {IsEdit ? "Edit User" : "Add User "}
                </Button>
              </ButtonGroup>
              <>
                {isUserFormVisible && (
                  <>
                    <TextField
                      margin="normal"
                      label="Name"
                      variant="filled"
                      onChange={(e) =>
                        setUserObject({
                          ...UserObject,
                          name: e.target.value,
                        })
                      }
                      value={UserObject.name}
                      fullWidth
                    />
                    <TextField
                      margin="normal"
                      label="Email"
                      variant="filled"
                      onChange={(e) =>
                        setUserObject({
                          ...UserObject,
                          email: e.target.value,
                        })
                      }
                      value={UserObject.email}
                      fullWidth
                    />
                    <TextField
                      margin="normal"
                      label="Password"
                      type="password"
                      variant="filled"
                      onChange={(e) =>
                        setUserObject({
                          ...UserObject,
                          password: e.target.value,
                        })
                      }
                      value={UserObject.password}
                      fullWidth
                    />
                    <TextField
                      margin="normal"
                      label="Phone"
                      type="phone"
                      variant="filled"
                      onChange={(e) =>
                        setUserObject({
                          ...UserObject,
                          phone: e.target.value,
                        })
                      }
                      value={UserObject.phone}
                      fullWidth
                    />
                    <FormControl variant="filled" margin="normal" fullWidth>
                      <InputLabel>Administrator</InputLabel>
                      <Select
                        value={UserObject.administrator}
                        onChange={(e) =>
                          setUserObject({
                            ...UserObject,
                            administrator: e.target.value,
                          })
                        }
                        MenuProps={{
                          classes: { paper: classes.dropdownStyle },
                        }}
                      >
                        <MenuItem value="enabled">Enabled</MenuItem>
                        <MenuItem value="disabled">Disabled</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl variant="filled" margin="normal" fullWidth>
                      <InputLabel>Global Settings</InputLabel>
                      <Select
                        value={UserObject.attributes.adminGlobalSettings}
                        onChange={(e) =>
                          setUserObject({
                            ...UserObject,
                            attributes: {
                              ...UserObject?.attributes,
                              adminGlobalSettings: e.target.value,
                            },
                          })
                        }
                        MenuProps={{
                          classes: { paper: classes.dropdownStyle },
                        }}
                      >
                        <MenuItem value="enabled">Enabled</MenuItem>
                        <MenuItem value="disabled">Disabled</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl variant="filled" margin="normal" fullWidth>
                      <InputLabel>Status</InputLabel>
                      <Select
                        value={UserObject.disabled}
                        onChange={(e) =>
                          setUserObject({
                            ...UserObject,
                            disabled: e.target.value,
                          })
                        }
                        MenuProps={{
                          classes: { paper: classes.dropdownStyle },
                        }}
                      >
                        <MenuItem value="enabled">Enabled</MenuItem>
                        <MenuItem value="disabled">Disabled</MenuItem>
                      </Select>
                    </FormControl>
                    {IsEdit ? (
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={applyDefaultAttributes}
                              onChange={(e) => {
                                setApplyDefaultAttributes(e.target.checked);
                              }}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                          }
                          label="Default Global Preferences"
                        />
                      </FormGroup>
                    ) : null}

                    <FormControl margin="normal" fullWidth>
                      {IsEdit ? (
                        <ButtonGroup
                          color="primary"
                          orientation="vertical"
                          disabled={!true}
                        >
                          <Button onClick={() => EditUser()}>
                            Save Changes
                          </Button>
                        </ButtonGroup>
                      ) : (
                        <ButtonGroup
                          color="primary"
                          orientation="vertical"
                          disabled={!true}
                        >
                          <Button onClick={() => addNewUser()}>Save</Button>
                        </ButtonGroup>
                      )}

                      <ButtonGroup
                        color="primary"
                        orientation="vertical"
                        disabled={!true}
                        className={classes.buttonGroup}
                      >
                        <Button
                          color="secondary"
                          onClick={() => {
                            handleReset();
                          }}
                        >
                          Cancel
                        </Button>
                      </ButtonGroup>
                    </FormControl>
                  </>
                )}
              </>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <MaterialTable
              columns={[
                {
                  title: t("sharedName"),
                  field: "name",
                  render: (rowData) => rowData.name,
                },
                {
                  title: t("userEmail"),
                  field: "email",
                  render: (rowData) => rowData.email,
                },
                {
                  title: "Phone",
                  field: "phone",
                  render: (rowData) => rowData.phone,
                },
                {
                  title: t("userAdmin"),
                  field: "administrator",
                  render: (rowData) => (rowData.administrator ? "Yes" : "No"),
                },
                {
                  title: "Global Settings",
                  field: "adminGlobalSettings",
                  render: (rowData) =>
                    rowData.attributes.adminGlobalSettings ? "Yes" : "No",
                },
                {
                  title: t("sharedDisabled"),
                  field: "sharedDisabled",
                  render: (rowData) => (rowData.disabled ? "Yes" : "No"),
                },
                {
                  title: "Action",
                  field: "Edit / Delete",
                  render: (rowData) => {
                    const EditCB = (e) => {
                      handleReset();
                      setApplyDefaultAttributes(false);
                      setUserObject({
                        ...UserObject,
                        id: rowData.id,
                        name: rowData.name,
                        email: rowData.email,
                        phone: rowData.phone ? rowData.phone : "",
                        disabled: rowData.disabled ? "disabled" : "enabled",
                        attributes: rowData.attributes,
                        administrator: rowData.administrator
                          ? "enabled"
                          : "disabled",
                      });

                      setSelectedRow(rowData.tableData.id);
                      setIsEdit(true);
                      setIsUserFormVisible(true);

                      e.stopPropagation();
                    };

                    const DeleteCb = async (e) => {
                      const response = await fetch(`/api/users/${rowData.id}`, {
                        method: "DELETE",
                      });
                      if (response) {
                        // formReset();
                        e.stopPropagation();
                        getAllUser();
                      }
                      e.stopPropagation();
                    };
                    return (
                      <div style={{ display: "flex" }}>
                        <EditView cb={EditCB} />
                        <DeleteView cb={DeleteCb} />
                      </div>
                    );
                  },
                },
              ]}
              data={_.orderBy(
                items,
                [(item) => item.name.toLowerCase()],
                ["asc"]
              )}
              title=""
              // icons={tableIcons}
              onRowClick={(event, rowData) => {}}
              options={{
                // exportButton: true,
                headerStyle: {
                  backgroundColor: "#1F1D2B",
                  color: "#FFFFFF",
                },
                sorting: true,
                paging: items.length > 10,
                pageSize: 10,
                pageSizeOptions: [],
                /* maxBodyHeight: calcHeight+'px', */
                defaultSort: "desc",
                rowStyle: (rowData) => ({
                  backgroundColor:
                    SelectedRow === rowData.tableData.id ? "#0d0d0d" : "",
                  display: rowData.id ? "table-row" : "none",
                }),
              }}
              style={{
                color: "#57c7d4",
                // overflowY: "scroll",
                // height: height / 2 - 60,
              }}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const UsersPage = () => (
  <>
    <MainToolbar />
    <EditCollectionView content={UsersView} editPath="/user" endpoint="users" />
  </>
);

const EditView = ({ cb }) => {
  const edit = (e) => {
    e.stopPropagation();
    cb(e);
  };
  return (
    <div
      style={{
        padding: "3px",
        margin: "0px 10px",
        borderRadius: "10px",
        cursor: "pointer",
      }}
    >
      <EditOutlinedIcon onClick={(e) => edit(e)} />
    </div>
  );
};

const DeleteView = ({ cb }) => {
  const [Dialog, setDialog] = useState({
    isOpen: false,
    handleSuccess: null,
    handleClose: null,
    decription: "Description",
    title: "titile",
    type: "conform",
    closeBtnText: "Close",
    confmBtnText: "Agree",
    handleDisagreeClose: null,
  });
  const deleteRow = (e) => {
    const fun = async () => {
      setDialog({ isOpen: false });
      await cb(e);
    };

    e.stopPropagation();
    setDialog({
      isOpen: true,
      handleClose: () => setDialog({ isOpen: false }),
      handleDisagreeClose: () => {
        setDialog({ isOpen: false });
        // setMileage(ValueBuffer);
      },
      handleSuccess: fun,
      decription: "Are you sure you want to delete this record  ?",
      title: "Alert !!",
      type: "conform",
      confmBtnText: "Yes  ",
      closeBtnText: "No ",
    });
    // setDialog({...Dialog,handleSuccess:() => {cb(e)}})
  };

  return (
    <div
      style={{
        padding: "3px",
        margin: "0px 10px",
        borderRadius: "10px",
        cursor: "pointer",
      }}
    >
      <DialogBox
        handleDisagreeClose={Dialog.handleDisagreeClose}
        handleClose={Dialog.handleClose}
        handleSuccess={Dialog.handleSuccess}
        open={Dialog.isOpen}
        decription={Dialog.decription}
        title={Dialog.title}
        type={Dialog.type}
        closeBtnText={Dialog.closeBtnText}
        confmBtnText={Dialog.confmBtnText}
      />
      <DeleteOutlineOutlinedIcon onClick={(e) => deleteRow(e)} />
    </div>
  );
};

const WarningDialog = ({ openWarning, warning, closeWarningCB }) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={openWarning}
        onClose={closeWarningCB}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.warningroot}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="secondary">
            {warning}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeWarningCB} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UsersPage;
