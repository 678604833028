import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import MainToolbar from "components/MainToolbar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "common/components/LocalizationProvider";
import {
  Stack,
  Checkbox,
  Divider,
  Chip,
  Grid,
  Paper,
  FormLabel,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  Switch,
  Select,
  Menu,
  MenuItem,
  Button,
  TextField,
  ButtonGroup,
  FormGroup,
  Typography,
} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import MaterialTable from "material-table";
import { useSelector, useDispatch } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { AddCircle, Edit, Save, Send } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import usePositionAttributes from "../common/attributes/usePositionAttributes";
import _ from "lodash";
import {
  CustomAlertBox,
  convertUnicode,
  DialogBox,
  useEffectAsync,
} from "reactHelper";
import EditCollectionView from "../EditCollectionView";

const useStyles = makeStyles((theme) => ({
  columnAction: {
    width: theme.spacing(1),
    padding: theme.spacing(0, 1),
  },
  root: {
    backgroundColor: "#12151e", // #1F1D2B'
    // height: "100%",
    // display: "flex",
    // flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& .MuiIcon-colorPrimary": {
      color: "#4ac7c9",
    },
    "& table tbody tr td": {
      border: "0px",
    },
    "& table tbody tr": {
      borderBottom: "1px solid #3f403f",
      display: "none",
    },
  },
  warningroot: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    "& .MuiPaper-root": {
      // backgroundColor:'#303030'
      backgroundColor: "#1F1D2B", // '#1F1D2B'
    },
    "& .MuiIcon-colorPrimary": {
      color: "#4ac7c9",
    },
  },
  chip: {
    margin: 2,
    backgroundColor: "#616161",
    color: "#fff",
  },
  tablechip: {
    margin: 1,
    backgroundColor: "#616161",
    color: "#fff",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  content: {
    flex: 1,
    overflow: "auto",
    padding: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(1, 2, 2),
    marginBottom: "10px",
  },
  heading: {
    color: "#00ac69",
  },
  fieldsetWithPadding: {
    padding: "0 25px",
  },
  dropdownStyle: {
    border: "1px solid black",
    borderRadius: "5%",
    backgroundColor: "#1F1D2B",
    color: "#57c7d4",
    "& li:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected:hover": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
    "& li.Mui-selected": {
      backgroundColor: "#12151e",
      color: "#FFFFFF",
    },
  },
}));

const DevicesViewNew = ({ updateTimestamp, onMenuClick }) => {
  const classes = useStyles();
  const t = useTranslation();

  const [items, setItems] = useState([]);
  const [users, setUsers] = useState([]);
  const [sendCommand, setSendCommand] = useState(false);
  const [accessConfigurationSequence, setAccessConfigurationSequence] =
    useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [Dialog, setDialog] = useState({
    isOpen: false,
    handleSuccess: null,
    handleClose: null,
    decription: null,
    title: null,
    type: "no",
    closeBtnText: "Close",
    confmBtnText: "Agree",
    handleDisagreeClose: null,
  });
  const [contactSequence, setContactSequence] = useState([0, 0, 0, 0, 0]);
  const [sequenceIds, setSequenceIds] = useState([]);
  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [buffer, setBuffer] = React.useState(10);
  const [period, setPeriod] = useState("today");
  const [from, setFrom] = useState(new Date());
  const [stop, setStop] = useState(0);
  const [moving, setMoving] = useState(0);
  const [SelectedRow, setSelectedRow] = useState(null);
  const [ShowMileageSaveButton, setShowMileageSaveButton] = useState(false);
  const [ShowHoursSaveButton, setShowHoursSaveButton] = useState(false);
  const [Hours, setHours] = useState(0);
  const [Mileage, setMileage] = useState(0);
  const [HoursBuffer, setHoursBuffer] = useState(0);
  const [MileageBuffer, setMileageBuffer] = useState(0);
  const positionAttributes = usePositionAttributes(t);
  const [to, setTo] = useState(new Date());
  const devices = useSelector((state) => Object.values(state.devices.items));
  const user = useSelector((state) => state.session.user);
  const positions = useSelector((state) =>
    Object.values(state.session.positions)
  );

  const progressRef = React.useRef(() => {});
  const [IsEdit, setIsEdit] = useState(false);
  const [selectedDeviceId, setselectedDeviceId] = useState(null);
  const [userId, setUserId] = useState("all");
  const [userDevices, setUserDevices] = useState([]);
  const [intervalCommand, setIntervalCommand] = useState("");
  const [intervalCommandPrepared, setIntervalCommandPrepared] = useState("");
  const [showUnassignedVehicles, setShowUnassignedVehicles] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [routeReportColumns, setRouteReportColumns] = useState([
    "latitude",
    "longitude",
    "speed",
    "id",
  ]);
  const [eventsReportColumns, setEventsReportColumns] = useState([
    "eventTime",
    "type",
    "attributes",
  ]);
  const [tripsReportColumns, setTripsReportColumns] = useState([
    "startTime",
    "startOdometer",
    "startAddress",
    "endTime",
    "endOdometer",
    "endAddress",
    "distance",
    "averageSpeed",
    "maxSpeed",
    "duration",
  ]);
  const [stopsReportColumns, setStopsReportColumns] = useState([
    "startOdometer",
    "address",
    "endTime",
    "duration",
    "startTime",
  ]);
  const [summaryReportColumns, setSummaryReportColumns] = useState([
    "startTime",
    "distance",
    "startOdometer",
    "endOdometer",
    "averageSpeed",
    "maxSpeed",
  ]);

  const [ignition, setIgnition] = useState(0);
  const [speedAlarm, setSpeedAlarm] = useState(0);
  const [sleepMode, setSleepMode] = useState(0);
  const [temperatureAlarm, setTemperatureAlarm] = useState(0);
  const [ignitionCode, setIgnitionCode] = useState(0);

  const [ShowAddDeviceContainer, setShowAddDeviceContainer] = useState(false);
  const [AddNewObject, setAddNewObject] = useState({
    id: 0,
    name: null,
    uniqueId: null,
    attributes: {},
  });
  const [LastDeviceIntervalCommand, setLastDeviceIntervalCommand] = useState(
    []
  );

  const [open, setOpen] = React.useState({
    Isshow: false,
    SuccesCBWithData: null,
    name: null,
  });

  const [openWarning, setOpenWarning] = useState(false);
  const [warning, setWarning] = useState(null);
  const handleWarningClose = () => {
    setOpenWarning(false);
  };
  let deviceStatus;

  const eventColumnsArray = [
    ["eventTime", "positionFixTime"],
    ["type", "sharedType"],
    ["geofenceId", "sharedGeofence"],
    ["maintenanceId", "sharedMaintenance"],
    ["attributes", "commandData"],
  ];
  const eventColumnsMap = new Map(eventColumnsArray);

  const stopColumnsArray = [
    ["startTime", "reportStartTime"],
    ["startOdometer", "positionOdometer"],
    ["address", "positionAddress"],
    ["endTime", "reportEndTime"],
    ["duration", "reportDuration"],
    ["engineHours", "reportEngineHours"],
    ["spentFuel", "reportSpentFuel"],
  ];
  const stopColumnsMap = new Map(stopColumnsArray);

  const tripColumnsArray = [
    ["startTime", "reportStartTime"],
    ["startOdometer", "reportStartOdometer"],
    ["startAddress", "reportStartAddress"],
    ["endTime", "reportEndTime"],
    ["endOdometer", "reportEndOdometer"],
    ["endAddress", "reportEndAddress"],
    ["distance", "sharedDistance"],
    ["averageSpeed", "reportAverageSpeed"],
    ["maxSpeed", "reportMaximumSpeed"],
    ["duration", "reportDuration"],
    ["spentFuel", "reportSpentFuel"],
    ["driverName", "sharedDriver"],
  ];
  const tripColumnsMap = new Map(tripColumnsArray);

  const summaryColumnsArray = [
    ["startTime", "reportStartDate"],
    ["distance", "sharedDistance"],
    ["startOdometer", "reportStartOdometer"],
    ["endOdometer", "reportEndOdometer"],
    ["averageSpeed", "reportAverageSpeed"],
    ["maxSpeed", "reportMaximumSpeed"],
    ["engineHours", "reportEngineHours"],
    ["spentFuel", "reportSpentFuel"],
  ];
  const summaryColumnsMap = new Map(summaryColumnsArray);

  const GetDeviceStatus = (status, deviceId) => {
    const found_pos = positions.find((element) => element.deviceId == deviceId);
    if (found_pos) {
      if (found_pos.attributes.motion == true) {
        return "motion";
      }
      if (status == "online") {
        return "online";
      }
      if (status == "offline") {
        return "offline";
      }
      return "unknown";
    }
    return "offline";
  };

  const accessConfigurationSequenceItems = [
    { id: 1, name: "Set Ignition" },
    { id: 2, name: "Speed Alarm" },
    { id: 3, name: "Sleep Mode" },
    { id: 4, name: "Temperature Alarm" },
    { id: 5, name: "Drivers Function" },
    { id: 6, name: "Driver Behaviour" },
    { id: 7, name: "Maintenance" },
    { id: 8, name: "Notifications" },
    { id: 9, name: "Groups" },
    { id: 10, name: "Temperature" },
    { id: 11, name: "Hours" },
    { id: 12, name: "Km's" },
    { id: 13, name: "Global Settings" },
    { id: 14, name: "Calendar" },
    { id: 15, name: "Assigned Group" },
  ];

  const ResetForm = () => {
    setAddNewObject({
      id: 0,
      name: null,
      uniqueId: null,
      phone: null,
      attributes: {
        intervalCommand: null,
        customIntervalCommand: null,
        intervalCommandUser: null,
        intervalCommandDate: null,
        accessConfiguration: null,
      },
      contact: null,
    });
    setSelectedRow(null);
    setEventsReportColumns(["eventTime", "type", "attributes"]);
    setRouteReportColumns(["latitude", "longitude", "speed", "id"]);
    setTripsReportColumns([
      "startTime",
      "startOdometer",
      "startAddress",
      "endTime",
      "endOdometer",
      "endAddress",
      "distance",
      "averageSpeed",
      "maxSpeed",
      "duration",
    ]);
    setStopsReportColumns([
      "startOdometer",
      "address",
      "endTime",
      "duration",
      "startTime",
    ]);

    setSummaryReportColumns([
      "startTime",
      "distance",
      "startOdometer",
      "endOdometer",
      "averageSpeed",
      "maxSpeed",
    ]);
    setIgnition(0);
    setSpeedAlarm(0);
    setSleepMode(0);
    setTemperatureAlarm(0);
    setIgnitionCode(0);
    setSequenceIds([]);
    setIntervalCommand("");
    setIntervalCommandPrepared("");
    setStop(0);
    setMoving(0);
    setShowAddDeviceContainer(false);
  };

  const handleClickOpen = (cb, data) => {
    setOpen({ Isshow: true, SuccesCBWithData: cb, name: data });
  };

  const convertAccessConfigurationToIds = (accessConfiguration) => {
    if (accessConfiguration) {
      const result = accessConfiguration
        .split(",")
        .map((item, index) => (Number(item) ? index + 1 : null));
      return result.filter(Boolean);
    }
    return [];
  };

  const convertIdsToAccessConfiguration = (ids) => {
    if (ids.length) {
      const accessConfigurationSequence = [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ];
      ids.forEach((item) => {
        accessConfigurationSequence[Number(item)] = 1;
      });
    }

    return accessConfigurationSequence;
  };

  const handleMileageClick = () => {
    if (!ShowMileageSaveButton) {
      setShowMileageSaveButton(true);
    }
  };

  const handleHoursClick = () => {
    if (!ShowHoursSaveButton) {
      setShowHoursSaveButton(true);
    }
  };

  const handleSuccess = () => {
    setDialog(false);
  };

  const handelSuccessClose = () => {
    open.SuccesCBWithData();
    handleClose();
  };

  const cancelForm = () => {
    ResetForm();
    setIsEdit(false);
  };

  const handleClose = () => {
    setOpen({ Isshow: false });
  };

  const getAllDevice = async () => {
    const response = await fetch("/api/devices?all=true");
    if (response.ok) {
      setItems(await response.json());
    }
  };

  const getAllUsers = async () => {
    const response = await fetch("/api/users");
    if (response.ok) {
      setUsers(await response.json());
    }
  };

  const getUserDevices = async () => {
    const ApiPath =
      userId != "all"
        ? `/api/devices?userId=${userId}`
        : "/api/devices?all=true";

    const response = await fetch(ApiPath);
    if (response.ok) {
      const userDevicesTemp = await response.json();
      let userDevicesIdTemp = userDevicesTemp.map((a) => a.id);
      userDevicesIdTemp = userId != "all" ? userDevicesIdTemp : [];

      setUserDevices(userDevicesIdTemp);
    }
  };

  const handleMileageSave = (selectedRow, type = "mileage") => {
    if (type == "mileage") {
      setShowMileageSaveButton(false);
    } else {
      setShowHoursSaveButton(false);
    }

    deviceStatus = GetDeviceStatus(selectedRow.status, selectedRow.id);

    const fun = async () => {
      setLoader(true);
      progressRef.current = () => {
        if (progress > 100) {
          setProgress(0);
          setBuffer(10);
        } else {
          const diff = Math.random() * 10;
          const diff2 = Math.random() * 10;
          setProgress(progress + diff);
          setBuffer(progress + diff + diff2);
        }
      };

      if (type == "mileage") {
        const commandResponse = await fetch("/api/commands/send", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            deviceId: selectedDeviceId,
            type: "custom",
            attributes: {
              data: `610,${(Mileage * 1000).toString()}`,
            },
          }),
        });
      }

      const response = await fetch(
        `/api/devices/${selectedDeviceId}/accumulators`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            deviceId: selectedDeviceId,
            totalDistance: Mileage ? Mileage * 1000 : 0,
            hours: Hours ? Hours * 1000 * 60 * 60 : 0,
          }),
        }
      );

      if (response.ok) {
        setLoader(false);
        setDialog({ isOpen: false });
        // SetSnackOpen();
      }
    };
    setDialog({
      isOpen: true,
      handleClose: () => setDialog({ isOpen: false }),
      handleDisagreeClose: () => {
        setDialog({ isOpen: false });
        setMileage(MileageBuffer);
      },
      handleSuccess: fun,
      decription: `If the ${
        type == "mileage" ? "mileage" : "hours"
      } is not set following installation then setting a new ${
        type == "mileage" ? "mileage" : "hours"
      } entry will only reflect future logs. Previous logs will show old ${
        type == "mileage" ? "mileage" : "hours"
      } `,
      title: "Stop",
      type: "conform",
      confmBtnText: "Agree",
      closeBtnText: "Disagree",
    });
  };

  const handleUserDeviceChange = async (val, rowData) => {
    if (userId != "all") {
      const response = await fetch("/api/permissions", {
        method: val.target.checked ? "POST" : "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId, deviceId: rowData?.id }),
      }).then((res) => {
        getUserDevices();
      });
    }
  };

  useEffect(() => {
    const accessConfigurationSequence = [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ];
    sequenceIds.forEach((item) => {
      accessConfigurationSequence[Number(item) - 1] = 1;
    });
    setAccessConfigurationSequence(accessConfigurationSequence);
    setAddNewObject({
      ...AddNewObject,
      attributes: {
        ...AddNewObject?.attributes,
        accessConfiguration: accessConfigurationSequence.join(","),
      },
    });
  }, [sequenceIds]);

  useEffect(() => {
    const contactSequenceArray = [
      ignition ? 1 : 0,
      speedAlarm ? Number(speedAlarm) : 0,
      sleepMode ? 1 : 0,
      temperatureAlarm ? Number(temperatureAlarm) : 0,
      ignitionCode || 0,
    ];
    setContactSequence(contactSequenceArray);
  }, [ignition, speedAlarm, sleepMode, temperatureAlarm, ignitionCode]);

  useEffect(() => {
    if (SelectedRow != null) {
      const Position = _.find(positions, {
        deviceId: vehicles[SelectedRow]?.id,
      });
      if (Position) {
        // alert("odometer -"+Position.attributes.odometer)
        if (
          Position.attributes.hours ||
          Position.attributes.hours != null ||
          Position.attributes.hours != undefined ||
          Position.attributes.hours > 0
        ) {
          setHours((Position.attributes.hours / (1000 * 60 * 60)).toFixed(0));
          setHoursBuffer(
            (Position.attributes.hours / (1000 * 60 * 60)).toFixed(0)
          );
        }

        if (
          Position.attributes.totalDistance ||
          Position.attributes.totalDistance != null ||
          Position.attributes.totalDistance != undefined ||
          Position.attributes.totalDistance > 0
        ) {
          setMileage((Position.attributes.totalDistance / 1000).toFixed(2));
          setMileageBuffer(
            (Position.attributes.totalDistance / 1000).toFixed(2)
          );
        }
      } else {
        setHours(0);
        setMileage(0);
        setHoursBuffer(0);
        setMileageBuffer(0);
      }
    } else {
      setHours(0);
      setMileage(0);
      setHoursBuffer(0);
      setMileageBuffer(0);
    }
  }, [SelectedRow]);

  const modifyObjectWithCommand = () => {
    setAddNewObject({
      ...AddNewObject,
      attributes: {
        ...AddNewObject.attributes,
        intervalCommand: intervalCommandPrepared,
        customIntervalCommand: intervalCommand == "custom",
        intervalCommandUser: user?.name,
        intervalCommandDate: moment().format("MMMM Do YYYY, h:mm:ss a"),
      },
    });
  };

  useEffect(() => {
    setAddNewObject({ ...AddNewObject, contact: contactSequence.join(",") });
  }, [contactSequence]);

  useEffect(() => {
    if (items.length) {
      let vehiclesTemp = _.orderBy(
        items,
        [(item) => item.name.toLowerCase()],
        ["asc"]
      );

      if (userId != "all" && !showUnassignedVehicles) {
        vehiclesTemp = _.filter(vehiclesTemp, (o) =>
          userDevices.includes(o?.id)
        );
      }

      setVehicles(vehiclesTemp);
    }
  }, [items, userDevices, showUnassignedVehicles]);

  useEffect(() => {
    const stopTemp = stop || 0;
    const movingTemp = moving || 0;
    if (intervalCommand == "custom") {
      setIntervalCommandPrepared(`102,${movingTemp},${stopTemp}`);
    }
    modifyObjectWithCommand();
  }, [intervalCommand, stop, moving]);

  const getChipValue = (selected) => {
    const a = [];
    accessConfigurationSequenceItems.map((d) => {
      if (selected.length > 0) {
        const x = selected.map((e) => {
          if (e === d.id) {
            a.push(d);
          }
        });
      }
    });
    return a;
  };

  useEffectAsync(async () => {
    await getAllDevice();
    await getAllUsers();
  }, [updateTimestamp]);

  const addNewDevice = () => {
    if (validateForm()) {
      fetch("/api/devices", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...AddNewObject,
          attributes: {
            ...AddNewObject.attributes,
            routeReportColumns: routeReportColumns.join(","),
            eventsReportColumns: eventsReportColumns.join(","),
            tripsReportColumns: tripsReportColumns.join(","),
            stopsReportColumns: stopsReportColumns.join(","),
            summaryReportColumns: summaryReportColumns.join(","),
          },
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          if (userId != "all") {
            unlinkDeviceWithAdmin(result?.id);
            linkDeviceWithUser(result?.id);
          } else {
            unlinkDeviceWithAdmin(result?.id);
          }
          // sendCommandInterval(result?.id);
          getAllDevice();
          ResetForm();
        });
    }
  };

  const linkDeviceWithUser = async (deviceId) => {
    if (userId != "all") {
      const response = await fetch("/api/permissions", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId, deviceId }),
      }).then((res) => {
        getUserDevices();
      });
    }
  };

  const validateForm = () => {
    if (
      _.find(
        items,
        (o) => o.uniqueId == AddNewObject.uniqueId && o.id != AddNewObject.id
      )
    ) {
      setWarning("Please use Unique IMEI No.");
      setOpenWarning(true);
      return false;
    }
    if (ignition || accessConfigurationSequence[0]) {
      if (ignition && accessConfigurationSequence[0]) {
        if (ignitionCode && ignitionCode != 0) {
          return true;
        }
        setWarning("Please enter Ignition Code");
        setOpenWarning(true);
        return false;
      }
      if (ignition && !accessConfigurationSequence[0]) {
        setWarning("Please tick both Ignition Boxes including under Sequence");
        setOpenWarning(true);
      } else {
        setWarning(
          "Please tick both Ignition Boxes including under Contact Sequence"
        );
        setOpenWarning(true);
      }

      return false;
    }

    return true;
  };

  const sendCommandInterval = async () => {
    const response = await fetch("/api/commands/send", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        deviceId: selectedDeviceId,
        type: "custom",
        attributes: {
          data: intervalCommandPrepared,
        },
      }),
    });

    modifyObjectWithCommand();
    EditDevice();
    return response;
  };

  const unlinkDeviceWithAdmin = async (deviceId) => {
    const response = await fetch("/api/permissions", {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userId: "1", deviceId }),
    }).then((res) => {
      getUserDevices();
    });
  };

  const EditDevice = () => {
    if (validateForm()) {
      fetch(`/api/devices/${selectedDeviceId}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...AddNewObject,
          attributes: {
            ...AddNewObject.attributes,
            routeReportColumns: routeReportColumns.join(","),
            eventsReportColumns: eventsReportColumns.join(","),
            tripsReportColumns: tripsReportColumns.join(","),
            stopsReportColumns: stopsReportColumns.join(","),
            summaryReportColumns: summaryReportColumns.join(","),
          },
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          setIsEdit(false);
          ResetForm();
          getAllDevice();
        });
    }
  };

  const deleteDevice = (rowData) => {
    // setTimeout(() => {
    //   const resp = fetch("/api/commands/send", {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //       deviceId: selectedDeviceId,
    //       type: "custom",
    //       textChannel: true,
    //       attributes: {
    //         data: `145,#00000${
    //           rowData.uniqueId.length > 7 ? rowData.uniqueId : rowData.uniqueId
    //           // : "0" + rowData.uniqueId
    //         }`,
    //       },
    //     }),
    //   });
    // }, 1000);

    fetch(`/api/devices/${rowData.id}`, {
      method: "DELETE",
    }).then((res) => {
      ResetForm();
      getAllDevice();
      // res.json()
    });
  };
  return (
    <div className={classes.root}>
      <DialogBox
        handleDisagreeClose={Dialog.handleDisagreeClose}
        handleClose={Dialog.handleClose}
        handleSuccess={Dialog.handleSuccess}
        open={Dialog.isOpen}
        decription={Dialog.decription}
        title={Dialog.title}
        type={Dialog.type}
        closeBtnText={Dialog.closeBtnText}
        confmBtnText={Dialog.confmBtnText}
      />
      <AlertDialog
        OnSuccess={handelSuccessClose}
        closeCB={handleClose}
        open={open.Isshow}
        name={open.name}
      />
      <WarningDialog
        closeWarningCB={handleWarningClose}
        openWarning={openWarning}
        warning={warning}
      />
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={3}
            lg={2}
            style={{ height: "calc(100vh - 40px)", overflow: "scroll" }}
          >
            <Paper className={classes.form}>
              <FormControl margin="normal" fullWidth>
                <>
                  <FormControl variant="filled" margin="normal" fullWidth>
                    <InputLabel>Users</InputLabel>
                    <Select
                      value={userId}
                      onChange={(e) => {
                        setUserId(e.target.value);
                      }}
                      MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    >
                      <MenuItem value="all">All</MenuItem>
                      {_.orderBy(
                        users,
                        [(item) => item.name.toLowerCase()],
                        ["asc"]
                      ).map((user) => (
                        <MenuItem value={user.id}>{user.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl margin="normal" fullWidth>
                    <ButtonGroup
                      color="primary"
                      orientation="vertical"
                      disabled={!userId}
                    >
                      <Button onClick={() => getUserDevices()}>
                        {t("reportShow")}
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                </>
              </FormControl>
            </Paper>

            {userId != "all" && (
              <Paper className={classes.form}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showUnassignedVehicles}
                        onChange={(event) => {
                          setShowUnassignedVehicles(event.target.checked);
                        }}
                      />
                    }
                    label="Show All Vehicles"
                  />
                </FormGroup>
              </Paper>
            )}

            <Paper className={classes.form}>
              <FormControl margin="normal" fullWidth>
                <Stack spacing={1}>
                  <Button
                    color="primary"
                    variant="contained"
                    startIcon={<AddCircle />}
                    onClick={() => {
                      setShowAddDeviceContainer(!ShowAddDeviceContainer);
                    }}
                  >
                    {IsEdit ? "Edit Device" : "Add Device"}
                  </Button>
                  {ShowAddDeviceContainer && (
                    <Button
                      onClick={() => cancelForm()}
                      color="secondary"
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  )}
                </Stack>
                {ShowAddDeviceContainer && (
                  <>
                    <TextField
                      margin="normal"
                      type="text"
                      onChange={(event) =>
                        setAddNewObject({
                          ...AddNewObject,
                          name: event.target.value,
                        })
                      }
                      label="Vehicle Name"
                      value={AddNewObject.name}
                      variant="filled"
                    />
                    <TextField
                      margin="normal"
                      type="text"
                      value={AddNewObject.uniqueId}
                      onChange={(event) =>
                        setAddNewObject({
                          ...AddNewObject,
                          uniqueId: event.target.value,
                        })
                      }
                      label="IMEI No"
                      variant="filled"
                    />
                    <TextField
                      margin="normal"
                      type="text"
                      value={AddNewObject.phone}
                      onChange={(event) =>
                        setAddNewObject({
                          ...AddNewObject,
                          phone: event.target.value,
                        })
                      }
                      label="Phone"
                      variant="filled"
                    />
                    <br />
                    <Divider />
                    {/* <Divider textAlign="left" light={true}> Sequence </Divider> */}

                    <FormControl
                      sx={{ m: 3 }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormLabel className={classes.heading} component="legend">
                        Sequence
                      </FormLabel>
                      <FormGroup>
                        <Stack>
                          {_.orderBy(
                            accessConfigurationSequenceItems,
                            ["name"],
                            ["asc"]
                          ).map((item) => (
                            <>
                              {item?.id != 11 && item?.id != 12 && (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={sequenceIds.includes(item?.id)}
                                      onChange={(event) => {
                                        const sequenceIdsTemp = sequenceIds;
                                        if (event.target.checked) {
                                          if (
                                            item.id == 4 &&
                                            !sequenceIds.includes(10)
                                          ) {
                                            setWarning(
                                              "Please tick Temperature and Temperature Alarm"
                                            );
                                            setOpenWarning(true);
                                          } else {
                                            sequenceIdsTemp.push(item.id);
                                          }

                                          setSequenceIds([...sequenceIdsTemp]);
                                        } else {
                                          if (item.id == 10) {
                                            _.remove(
                                              sequenceIdsTemp,
                                              (n) => n == 4
                                            );
                                          }
                                          _.remove(
                                            sequenceIdsTemp,
                                            (n) => n == item.id
                                          );
                                          setSequenceIds([...sequenceIdsTemp]);
                                        }
                                      }}
                                      name={item?.name}
                                      color="primary"
                                    />
                                  }
                                  label={item?.name}
                                />
                              )}
                            </>
                          ))}
                        </Stack>
                      </FormGroup>
                    </FormControl>

                    {/* <FormControl variant="filled" margin="normal" fullWidth>
                      <InputLabel>Select Sequence</InputLabel>

                      <Select
                        multiple
                        value={[...sequenceIds]}
                        onChange={(event) => {
                          let sequenceIdsTemp = event.target.value;
                          setSequenceIds(sequenceIdsTemp);
                        }}
                        renderValue={(selected) => (
                          <ChipsSelectView selected={getChipValue(selected)} />
                        )}
                        MenuProps={{
                          classes: { paper: classes.dropdownStyle },
                        }}
                      >
                        {accessConfigurationSequenceItems.map((rowData, index) => (
                          <MenuItem
                            key={Number(rowData.id)}
                            value={Number(rowData.id)}
                          >
                            <Checkbox
                              checked={
                                sequenceIds.indexOf(Number(rowData.id)) > -1
                              }
                            />
                            <ListItemText primary={rowData?.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}
                    <br />
                    {/* <Divider textAlign="left" light={true}>Contact Sequence</Divider> */}

                    <FormControl
                      sx={{ m: 3 }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormLabel className={classes.heading} component="legend">
                        Contact Sequence
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={Number(ignition)}
                              onChange={(event) => {
                                setIgnition(event.target.checked);
                              }}
                              name="Set Ignition"
                              color="primary"
                            />
                          }
                          label="Ignition"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={Number(sleepMode)}
                              onChange={(event) => {
                                setSleepMode(event.target.checked);
                              }}
                              name="Sleep Mode"
                              color="primary"
                            />
                          }
                          label="Sleep Mode"
                        />

                        <TextField
                          margin="normal"
                          type="text"
                          required={!!accessConfigurationSequence[1]}
                          error={
                            !!accessConfigurationSequence[1] && !speedAlarm
                          }
                          onChange={(event) => {
                            setSpeedAlarm(event.target.value);
                          }}
                          label="Speed Alarm"
                          value={speedAlarm}
                          variant="filled"
                        />
                        <TextField
                          margin="normal"
                          type="text"
                          fullWidth
                          required={!!sleepMode}
                          error={sleepMode && !temperatureAlarm}
                          onChange={(event) => {
                            setTemperatureAlarm(event.target.value);
                          }}
                          label="Temperature Alarm"
                          value={Number(temperatureAlarm)}
                          variant="filled"
                        />
                        <TextField
                          margin="normal"
                          type="text"
                          fullWidth
                          required={
                            !!(ignition || accessConfigurationSequence[0])
                          }
                          error={
                            (ignition || accessConfigurationSequence[0]) &&
                            (!ignitionCode || ignitionCode == "0")
                          }
                          onChange={(event) => {
                            setIgnitionCode(event.target.value);
                          }}
                          label="Ignition Code"
                          onBlur={() => {
                            setIgnitionCode(
                              ignitionCode.length ? ignitionCode : 0
                            );
                          }}
                          value={ignitionCode}
                          variant="filled"
                        />
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      sx={{ m: 3 }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormLabel className={classes.heading} component="legend">
                        Route Report Columns
                      </FormLabel>
                      <FormGroup>
                        {Object.keys(positionAttributes).map((key) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={routeReportColumns.includes(key)}
                                onChange={() => {
                                  let routeReportColumnsTemp =
                                    routeReportColumns;
                                  if (!routeReportColumnsTemp.includes(key)) {
                                    routeReportColumnsTemp.push(key);
                                  } else {
                                    routeReportColumnsTemp.splice(
                                      routeReportColumns.indexOf(key),
                                      1
                                    );
                                  }

                                  setRouteReportColumns([
                                    ...routeReportColumnsTemp,
                                  ]);
                                }}
                                name={key}
                                color="primary"
                              />
                            }
                            label={positionAttributes[key].name}
                          />
                        ))}
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      sx={{ m: 3 }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormLabel className={classes.heading} component="legend">
                        Events Report Columns
                      </FormLabel>
                      <FormGroup>
                        {eventColumnsArray.map(([key, string]) => (
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={eventsReportColumns.includes(key)}
                                  onChange={() => {
                                    let eventsReportColumnsTemp =
                                      eventsReportColumns;
                                    if (
                                      !eventsReportColumnsTemp.includes(key)
                                    ) {
                                      eventsReportColumnsTemp.push(key);
                                    } else {
                                      eventsReportColumnsTemp.splice(
                                        eventsReportColumns.indexOf(key),
                                        1
                                      );
                                    }

                                    setEventsReportColumns([
                                      ...eventsReportColumnsTemp,
                                    ]);
                                  }}
                                  name={key}
                                  color="primary"
                                />
                              }
                              label={t(eventColumnsMap.get(key))}
                            />
                          </>
                        ))}
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      sx={{ m: 3 }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormLabel className={classes.heading} component="legend">
                        Trip Report Columns
                      </FormLabel>
                      <FormGroup>
                        {tripColumnsArray.map(([key, string]) => (
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={tripsReportColumns.includes(key)}
                                  onChange={() => {
                                    let tripsReportColumnsTemp =
                                      tripsReportColumns;
                                    if (!tripsReportColumnsTemp.includes(key)) {
                                      tripsReportColumnsTemp.push(key);
                                    } else {
                                      tripsReportColumnsTemp.splice(
                                        tripsReportColumns.indexOf(key),
                                        1
                                      );
                                    }

                                    setTripsReportColumns([
                                      ...tripsReportColumnsTemp,
                                    ]);
                                  }}
                                  name={key}
                                  color="primary"
                                />
                              }
                              label={t(tripColumnsMap.get(key))}
                            />
                          </>
                        ))}
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      sx={{ m: 3 }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormLabel className={classes.heading} component="legend">
                        Stops Report Columns
                      </FormLabel>
                      <FormGroup>
                        {stopColumnsArray.map(([key, string]) => (
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={stopsReportColumns.includes(key)}
                                  onChange={() => {
                                    let stopsReportColumnsTemp =
                                      stopsReportColumns;
                                    if (!stopsReportColumnsTemp.includes(key)) {
                                      stopsReportColumnsTemp.push(key);
                                    } else {
                                      stopsReportColumnsTemp.splice(
                                        stopsReportColumns.indexOf(key),
                                        1
                                      );
                                    }

                                    setStopsReportColumns([
                                      ...stopsReportColumnsTemp,
                                    ]);
                                  }}
                                  name={key}
                                  color="primary"
                                />
                              }
                              label={t(stopColumnsMap.get(key))}
                            />
                          </>
                        ))}
                      </FormGroup>
                    </FormControl>
                    <FormControl
                      sx={{ m: 3 }}
                      component="fieldset"
                      variant="standard"
                    >
                      <FormLabel className={classes.heading} component="legend">
                        Summary Report Columns
                      </FormLabel>
                      <FormGroup>
                        {summaryColumnsArray.map(([key, string]) => (
                          <>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={summaryReportColumns.includes(key)}
                                  onChange={() => {
                                    let summaryReportColumnsTemp =
                                      summaryReportColumns;
                                    if (
                                      !summaryReportColumnsTemp.includes(key)
                                    ) {
                                      summaryReportColumnsTemp.push(key);
                                    } else {
                                      summaryReportColumnsTemp.splice(
                                        summaryReportColumns.indexOf(key),
                                        1
                                      );
                                    }

                                    setSummaryReportColumns([
                                      ...summaryReportColumnsTemp,
                                    ]);
                                  }}
                                  name={key}
                                  color="primary"
                                />
                              }
                              label={t(summaryColumnsMap.get(key))}
                            />
                          </>
                        ))}
                      </FormGroup>
                    </FormControl>
                    <Stack spacing={2}>
                      {AddNewObject.name && AddNewObject.uniqueId && IsEdit ? (
                        <Button
                          onClick={() => EditDevice()}
                          color="primary"
                          variant="outlined"
                          startIcon={<Edit />}
                        >
                          Save Changes
                        </Button>
                      ) : (
                        <Button
                          onClick={() => addNewDevice()}
                          color="primary"
                          variant="outlined"
                          startIcon={<Save />}
                        >
                          Save
                        </Button>
                      )}
                    </Stack>
                  </>
                )}
              </FormControl>
            </Paper>
            {AddNewObject.name && AddNewObject.uniqueId && IsEdit && (
              <Paper className={classes.form}>
                <FormControl
                  className={classes.fieldsetWithPadding}
                  component="fieldset"
                  margin="normal"
                  fullWidth
                >
                  <FormLabel className={classes.heading} component="legend">
                    Set Interval
                  </FormLabel>
                  <>
                    <FormGroup row>
                      <FormControl variant="filled" margin="normal" fullWidth>
                        <InputLabel>Interval Commands</InputLabel>
                        <Select
                          value={intervalCommand}
                          onChange={(e) => {
                            setIntervalCommand(e.target.value);
                            if (e.target.value != "custom") {
                              setIntervalCommandPrepared(e.target.value);
                              setStop(0);
                              setMoving(0);
                            }
                          }}
                          MenuProps={{
                            classes: { paper: classes.dropdownStyle },
                          }}
                        >
                          <MenuItem value="102,10,60">
                            Set Interval of Server 1 - 10 Sec (Ignition On) and
                            60 Sec (Ignition Off)
                          </MenuItem>
                          <MenuItem value="102,10,120">
                            Set Interval of Server 1 - 10 Sec (Ignition On) and
                            120 Sec (Ignition Off)
                          </MenuItem>
                          <MenuItem value="102,10,240">
                            Set Interval of Server 1 - 10 Sec (Ignition On) and
                            240 Sec (Ignition Off)
                          </MenuItem>
                          <MenuItem value="102,10,60">
                            Set Interval of Server 2 - 10 Sec (Ignition On) and
                            60 Sec (Ignition Off)
                          </MenuItem>
                          <MenuItem value="102,10,120">
                            Set Interval of Server 2 - 10 Sec (Ignition On) and
                            120 Sec (Ignition Off)
                          </MenuItem>
                          <MenuItem value="102,10,240">
                            Set Interval of Server 2 - 10 Sec (Ignition On) and
                            240 Sec (Ignition Off)
                          </MenuItem>
                          <MenuItem value="custom">Custom</MenuItem>
                        </Select>
                      </FormControl>
                      {intervalCommand == "custom" && (
                        <>
                          <TextField
                            margin="normal"
                            type="number"
                            onChange={(event) => {
                              setMoving(event.target.value);
                            }}
                            label="Moving"
                            value={moving}
                            variant="filled"
                          />
                          <TextField
                            margin="normal"
                            type="number"
                            onChange={(event) => {
                              setStop(event.target.value);
                            }}
                            label="Stop"
                            value={stop}
                            variant="filled"
                          />
                        </>
                      )}
                    </FormGroup>
                  </>
                </FormControl>
                <FormControl margin="normal" fullWidth>
                  <Button
                    onClick={() => sendCommandInterval()}
                    color="primary"
                    variant="outlined"
                    startIcon={<Send />}
                  >
                    Send
                  </Button>
                </FormControl>
              </Paper>
            )}
            {AddNewObject.name && AddNewObject.uniqueId && IsEdit && (
              <Paper className={classes.form}>
                <FormControl
                  component="fieldset"
                  className={classes.fieldsetWithPadding}
                  variant="standard"
                  margin="normal"
                  fullWidth
                >
                  <>
                    <FormControl variant="filled" margin="normal" fullWidth>
                      <FormLabel className={classes.heading} component="legend">
                        Set Hours
                      </FormLabel>
                      <TextField
                        style={{ width: "100%" }}
                        margin="normal"
                        value={Hours}
                        onClick={handleHoursClick}
                        onChange={(event) => setHours(event.target.value)}
                        label="Enter Hours"
                        variant="filled"
                      />
                    </FormControl>
                  </>
                </FormControl>
                <FormControl margin="normal" fullWidth>
                  {ShowHoursSaveButton ? (
                    <div className={classes.btn}>
                      <Stack spacing={2}>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={(e) =>
                            handleMileageSave(vehicles[SelectedRow], "hours")
                          }
                        >
                          Save
                        </Button>
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={(e) => {
                            setHours(HoursBuffer);
                            setShowHoursSaveButton(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    </div>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Paper>
            )}

            {AddNewObject.name && AddNewObject.uniqueId && IsEdit && (
              <Paper className={classes.form}>
                <FormControl
                  className={classes.fieldsetWithPadding}
                  component="fieldset"
                  margin="normal"
                  fullWidth
                >
                  <>
                    <FormControl variant="filled" margin="normal" fullWidth>
                      <FormLabel className={classes.heading} component="legend">
                        Set Km's
                      </FormLabel>
                      <TextField
                        style={{ width: "100%" }}
                        margin="normal"
                        value={Mileage}
                        onClick={handleMileageClick}
                        onChange={(event) => setMileage(event.target.value)}
                        label="Enter Mileage (km)"
                        variant="filled"
                      />
                    </FormControl>
                  </>
                </FormControl>
                <FormControl margin="normal" fullWidth>
                  {ShowMileageSaveButton ? (
                    <div className={classes.btn}>
                      <Stack spacing={2}>
                        <Button
                          color="primary"
                          variant="outlined"
                          onClick={(e) =>
                            handleMileageSave(vehicles[SelectedRow], "mileage")
                          }
                        >
                          Save
                        </Button>
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={(e) => {
                            setMileage(MileageBuffer);
                            setShowMileageSaveButton(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    </div>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Paper>
            )}
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            {loader ? (
              <LinearProgress
                variant="buffer"
                value={progress}
                valueBuffer={buffer}
              />
            ) : (
              ""
            )}
            {ShowAddDeviceContainer && (
              <>
                <MaterialTable
                  title="Interval Commands"
                  columns={[
                    {
                      title: "Interval Command",
                      field: "intervalCommand",
                      render: (rowData) => rowData.intervalCommand,
                    },
                    {
                      title: "Sent on",
                      field: "intervalCommandDate",
                      render: (rowData) => rowData.intervalCommandDate,
                    },
                    {
                      title: "Sent By",
                      field: "intervalCommandUser",
                      render: (rowData) => rowData.intervalCommandUser,
                    },
                  ]}
                  data={LastDeviceIntervalCommand}
                  // icons={tableIcons}
                  options={{
                    search: false,
                    actionsColumnIndex: -1,
                    // exportButton: true,
                    cellStyle: {
                      padding: "6px 16px",
                      borderBottom: "1px solid #3f403f",
                    },
                    actionsCellStyle: {
                      borderBottom: "1px solid #3f403f",
                    },
                    headerStyle: {
                      backgroundColor: "#1F1D2B",
                      color: "#FFFFFF",
                      borderBottom: "2px solid #cbcad0",
                    },

                    sorting: true,
                    paging: false,
                    /* maxBodyHeight: calcHeight+'px', */
                    defaultSort: "asc",
                  }}
                  style={{ color: "#57c7d4" }}
                />
                <br />
              </>
            )}
            {items ? (
              <MaterialTable
                columns={[
                  {
                    title: "Select",
                    field: "",
                    render: (rowData) => (
                      <Checkbox
                        checked={userDevices.includes(rowData?.id)}
                        onChange={(event) =>
                          handleUserDeviceChange(event, rowData)
                        }
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    ),
                  },
                  {
                    title: "Vehicle Name",
                    field: "name",
                    render: (rowData) => rowData.name,
                  },
                  {
                    title: "IMEI No", // t('deviceIdentifier'),
                    field: "uniqueId",
                    render: (rowData) => rowData.uniqueId,
                  },
                ]}
                actions={[
                  {
                    icon: "edit",
                    tooltip: "Edit",
                    iconProps: {
                      color: "primary",
                    },
                    onClick: (event, rowData) => {
                      setselectedDeviceId(rowData.id);
                      setSelectedRow(rowData.tableData.id);

                      setIsEdit(true);
                      if (rowData.contact) {
                        const contactData = rowData.contact.split(",");
                        setIgnition(Number(contactData[0]));
                        setSpeedAlarm(Number(contactData[1]));
                        setSleepMode(Number(contactData[2]));
                        setTemperatureAlarm(Number(contactData[3]));
                        setIgnitionCode(contactData[4]);
                      } else {
                        setIgnition(0);
                        setSpeedAlarm(0);
                        setSleepMode(0);
                        setTemperatureAlarm(0);
                        setIgnitionCode(0);
                      }

                      let routeReportColumnsTemp = !!rowData.attributes
                        .routeReportColumns
                        ? rowData.attributes.routeReportColumns.split(",")
                        : [];
                      setRouteReportColumns(routeReportColumnsTemp);

                      let eventsReportColumnsTemp = !!rowData.attributes
                        .eventsReportColumns
                        ? rowData.attributes.eventsReportColumns.split(",")
                        : [];
                      setEventsReportColumns(eventsReportColumnsTemp);

                      let tripsReportColumnsTemp = !!rowData.attributes
                        .tripsReportColumns
                        ? rowData.attributes.tripsReportColumns.split(",")
                        : [];
                      setTripsReportColumns(tripsReportColumnsTemp);

                      let stopsReportColumnsTemp = !!rowData.attributes
                        .stopsReportColumns
                        ? rowData.attributes.stopsReportColumns.split(",")
                        : [];
                      setStopsReportColumns(stopsReportColumnsTemp);

                      let summaryReportColumnsTemp = !!rowData.attributes
                        .summaryReportColumns
                        ? rowData.attributes.summaryReportColumns.split(",")
                        : [];
                      setSummaryReportColumns(summaryReportColumnsTemp);

                      setSequenceIds(
                        convertAccessConfigurationToIds(
                          rowData?.attributes?.accessConfiguration
                        )
                      );
                      if (rowData?.attributes?.customIntervalCommand) {
                        const intervalCommandSplit =
                          rowData?.attributes?.intervalCommand?.split(",");
                        setIntervalCommand("custom");
                        setMoving(Number(intervalCommandSplit[1]));
                        setStop(Number(intervalCommandSplit[2]));
                      } else {
                        // setIntervalCommand(
                        //   rowData?.attributes?.intervalCommand
                        // );
                        setMoving(Number(0));
                        setStop(Number(0));
                      }
                      setShowAddDeviceContainer(true);
                      setLastDeviceIntervalCommand([
                        {
                          intervalCommand: rowData?.attributes?.intervalCommand,
                          intervalCommandDate:
                            rowData?.attributes?.intervalCommandDate,
                          intervalCommandUser:
                            rowData?.attributes?.intervalCommandUser,
                        },
                      ]);

                      setAddNewObject({
                        ...AddNewObject,
                        name: rowData.name,
                        uniqueId: rowData.uniqueId,
                        attributes: {
                          ...rowData?.attributes,
                          accessConfiguration:
                            rowData?.attributes?.accessConfiguration,
                        },
                        id: rowData.id,
                        contact: rowData.contact,
                        phone: rowData.phone,
                      });
                    },
                  },
                  {
                    icon: "delete",
                    tooltip: "Delete",
                    iconProps: {
                      color: "primary",
                    },
                    cellStyle: {
                      padding: "10px 16px",
                      borderBottom: "1px solid #3f403f",
                    },
                    onClick: (event, rowData) => {
                      ResetForm();
                      handleClickOpen(
                        () => deleteDevice(rowData),
                        rowData.name
                      );
                      // ;
                    },
                  },
                ]}
                // editable={{

                //   onRowUpdate: (newData, oldData) =>
                //     new Promise((resolve) => {
                //      // handleRowUpdate(newData, oldData, resolve);
                // }),
                // onRowDelete: (oldData) =>
                //   new Promise((resolve) => {
                //     //handleRowDelete(oldData, resolve)
                //   }),
                // }}
                data={vehicles}
                title="Vehicles"
                // icons={tableIcons}
                options={{
                  actionsColumnIndex: -1,
                  // exportButton: true,
                  cellStyle: {
                    padding: "6px 16px",
                    borderBottom: "1px solid #3f403f",
                  },
                  actionsCellStyle: {
                    borderBottom: "1px solid #3f403f",
                  },
                  headerStyle: {
                    backgroundColor: "#1F1D2B",
                    color: "#FFFFFF",
                    borderBottom: "2px solid #cbcad0",
                  },
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      SelectedRow === rowData.tableData.id ? "#0d0d0d" : "",
                    display: rowData.id ? "table-row" : "none",
                  }),

                  sorting: true,
                  paging: items.length > 10,
                  pageSize: 10,
                  pageSizeOptions: [],
                  /* maxBodyHeight: calcHeight+'px', */
                  defaultSort: "asc",
                }}
                style={{ color: "#57c7d4" }}
              />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const DevicesPage = () => (
  <>
    <MainToolbar />
    <EditCollectionView
      content={DevicesViewNew}
      editPath="/settings/driver"
      endpoint="devices"
    />
  </>
);

const ChipsSelectView = ({ selected, type }) => {
  const classes = useStyles();

  return (
    <div className={classes.chips}>
      {selected.length > 0 &&
        selected.map(
          (d) =>
            d && (
              <Chip
                key={d.id}
                label={d.name}
                className={type == "table" ? classes.tablechip : classes.chip}
              />
            )
        )}
    </div>
  );
};

const AlertDialog = ({ open, OnSuccess, closeCB, name }) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={closeCB}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.root}
      >
        <DialogTitle id="alert-dialog-title">Confirm ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="secondary">
            Are you sure you want to delete Device {name}, this cannot be
            reversed?.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCB} color="primary">
            No
          </Button>
          <Button onClick={OnSuccess} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const WarningDialog = ({ openWarning, warning, closeWarningCB }) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={openWarning}
        onClose={closeWarningCB}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.warningroot}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" color="secondary">
            {warning}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeWarningCB} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DevicesPage;
